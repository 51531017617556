import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import PageHeader from '../components/PageHeader';
import Content from '../components/Content';
import Layout from '../components/Layout';

// Export Template for use in CMS preview
const HomePageTemplate = ({
  Title,
  Sub_Title,
  Featured_Image,
  body,
  featuredImageOffsetX,
  featuredImageOffsetY
}) => {
  return (
    <main className="Home">
      <PageHeader
        large
        title={Title}
        subtitle={Sub_Title}
        backgroundImage={Featured_Image}
        imageOffsetX={featuredImageOffsetX}
        imageOffsetY={featuredImageOffsetY}
      />

      <section className="section">
        <div className="container">
          <Content source={body} />
        </div>
      </section>
    </main>
  );
};

const HomePage = () => {
  const data = useStaticQuery(pageQuery);
  const page = data.directus.Home_Page;
  return (
    <Layout meta={page.Default_Seo || false} title={page.Title || false}>
      <HomePageTemplate {...page} />
    </Layout>
  );
};

export default HomePage;

const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage {
    directus {
      Home_Page {
        Default_Seo {
          Meta_Title
          Meta_Description
          Share_Image {
            id
          }
        }
        Featured_Image {
          id
          filename_disk
        }
        featuredImageOffsetX
        featuredImageOffsetY
        Title
        Sub_Title
        body
      }
    }
  }
`;
